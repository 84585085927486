<template>
  <icon-base :width="size + 'px'" :fill="color">
    <path class="st0" d="M172.2,1.1H64.6c-0.9,0-1.8,0.4-2.5,1L25.3,38.9c-0.7,0.7-1,1.6-1,2.5v154c0,1.9,1.6,3.5,3.5,3.5h144.3  c1.9,0,3.5-1.6,3.5-3.5V4.6C175.7,2.7,174.1,1.1,172.2,1.1z M117.1,13.8c0-0.4,0.3-0.7,0.7-0.7h10.1c0.4,0,0.7,0.3,0.7,0.7v33  c0,0.4-0.3,0.7-0.7,0.7h-10.1c-0.4,0-0.7-0.3-0.7-0.7V13.8z M94.3,13.8c0-0.4,0.3-0.7,0.7-0.7H105c0.4,0,0.7,0.3,0.7,0.7v33  c0,0.4-0.3,0.7-0.7,0.7H95c-0.4,0-0.7-0.3-0.7-0.7V13.8z M71.5,13.8c0-0.4,0.3-0.7,0.7-0.7h10.1c0.4,0,0.7,0.3,0.7,0.7v33  c0,0.4-0.3,0.7-0.7,0.7H72.1c-0.4,0-0.7-0.3-0.7-0.7V13.8z M146.4,166.5c0,1.5-1.2,2.8-2.8,2.8H56.4c-1.5,0-2.8-1.2-2.8-2.8v-63.7  c0-1.5,1.2-2.8,2.8-2.8h87.2c1.5,0,2.8,1.2,2.8,2.8V166.5z M151.4,46.8c0,0.4-0.3,0.7-0.7,0.7h-10.1c-0.4,0-0.7-0.3-0.7-0.7v-33  c0-0.4,0.3-0.7,0.7-0.7h10.1c0.4,0,0.7,0.3,0.7,0.7V46.8z"/>`
  </icon-base>
</template>

<script>
export default {
  name: 'iconStorage',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

